import carnival from "../assets/images/posters/Design Posters/Non Tech/Carnivals.webp";
import cinemania from "../assets/images/posters/Design Posters/Non Tech/Cinemania.webp";
import exitquest from "../assets/images/posters/Design Posters/Non Tech/ExitQuest.webp";
import iplthunder from "../assets/images/posters/Design Posters/Non Tech/IPL_thunder.webp";
import kandukondain from "../assets/images/posters/Design Posters/Non Tech/KandukondainKandukondain.webp";
import melocity from "../assets/images/posters/Design Posters/Non Tech/Melocity.webp"
import toonfest from "../assets/images/posters/Design Posters/Non Tech/ToonFest.webp";
import vilambaraidaivelai from "../assets/images/posters/Design Posters/Non Tech/VilambaraIdaivelai.webp"
const NonTech = [
 
 
  {
    eventname: "Cinemania",
    image: cinemania,
   
  },
  {
    eventname: "Exit Quest",
    image: exitquest,
    
  },
  {
    eventname: "IPL Thunder",
    image: iplthunder,
    
  },
  {
    eventname: "Kandukondain Kandukondain",
    image: kandukondain,
    
  },
  {
    eventname: "Melocity 2.0",
    image: melocity,
    
  },
  {
    eventname: "Toon Fest",
    image: toonfest,
    
  },
  {
    eventname: "Vilambara Idaivelai",
    image: vilambaraidaivelai,
    
  },
  
  
  {
    eventname: "Carnival",
    image: carnival,
    
  },
];

export default NonTech;
