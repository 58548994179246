import ampedup from "../assets/images/posters/Design Posters/Tech/AmpedUP.webp";
import botrush from "../assets/images/posters/Design Posters/Tech/BotRush.webp";
import cashclash from "../assets/images/posters/Design Posters/Tech/CashClash.webp";
import cybersprint from "../assets/images/posters/Design Posters/Tech/cyberSprint.webp";
import exhivision from "../assets/images/posters/Design Posters/Tech/Exhivision.webp";
import mindbrawl from "../assets/images/posters/Design Posters/Tech/MindBrawl.webp";
import witbit from "../assets/images/posters/Design Posters/Tech/Witbit.webp";


const Tech = [
  {
    eventname: "Exhivision",
    image: exhivision,
  },
  {
    eventname: "Amped Up",
    image: ampedup,
  },
  {
    eventname: "Bot Rush",
    image: botrush,
  },
  {
    eventname: "Cash Clash",
    image: cashclash,
  },
  {
    eventname: "Cyber Sprint",
    image: cybersprint,
  },
  {
    eventname: "Mindbrawl",
    image: mindbrawl,
  },
  {
    eventname: "Wit Bit",
    image: witbit,
  },
];

export default Tech;
