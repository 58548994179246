import data from "../assets/images/posters/Design Posters/Workshops/data.webp"
import finance from "../assets/images/posters/Design Posters/Workshops/finance.webp"
import vivado from "../assets/images/posters/Design Posters/Workshops/vivado.webp"


const WorkshopDetails = [
    {
        eventname: "Data Analytics using Power BI",
        image: data,
        
    },
    {
        eventname: "Finance",
        image: finance,
        
    },
    {
        eventname: "VLSI and ASIC",
        image: vivado,
        
    },
]

export default WorkshopDetails;