import inin from "../assets/images/sponsors/indirainsignia.png";
import mcafe from "../assets/images/sponsors/makerscafe.png";
import gurunath from "../assets/images/sponsors/gurunath.png";
import rajams from "../assets/images/sponsors/rajarams.png";
import hayati from "../assets/images/sponsors/hayati.png";
import eeci from "../assets/images/sponsors/eecigate.PNG";
import time from "../assets/images/sponsors/time.jpg";
import rajans from "../assets/images/sponsors/rajans.png";

const daySponsors = [
  {
    name: "",
    link: "https://indrainsignia.co.in/",
    image: inin,
  },
  {
    name: "",
    link: "https://www.eecigate.in/",
    image: eeci,
  },
  {
    name: "",
    link: "",
    image: gurunath,
  },
  {
    name: "",
    link: "https://www.instagram.com/hayati_garments",
    image: hayati,
  },
  {
    name: "",
    link: "https://www.instagram.com/makerscafechennai?igsh=eDZlOWk5NmltemRo",
    image: mcafe,
  },
  
  {
    name: "",
    link: "https://rajams.in/",
    image: rajams,
  },
  
  
  {
    name: "",
    link: "",
    image: time,
  },
  {
    name: "",
    link: "",
    image: rajans,
  },
];

export { daySponsors };
