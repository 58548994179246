import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../src/assets/images/eceaWhite.png";
import MenuIcon from "@mui/icons-material/Menu";

function NavBar() {
  // Pages and Links
  const pages = [
    "Home",
    "About Us",
    "Tech Events",
    "Non Tech Events",
    "Workshop",
    "Login",
  ];
  const links = {
    Home: "/",
    "Tech Events": "/techevents",
    "Non Tech Events": "/nontechevents",
    "About Us": "/aboutus",
    Login: "/Vision-ID",
    Workshop: "/workshop",
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="absolute" color="transparent">
        <Toolbar>
          <Avatar
            alt="ECEA"
            src={Logo}
            sx={{ display: { xs: "none", md: "block" }, marginRight: "10px" }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "Title",
              fontWeight: 900,
              letterSpacing: ".3rem",
              color: "#22d3ee",
              fontSize: "27px",
              textDecoration: "none",
            }}
          >
            ECEA
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "#fff" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                mt: "1px",
                "& .MuiMenu-paper": { backgroundColor: "#000" },
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <Link to={links[page]}>
                  <MenuItem
                    sx={{ backgroundColor: "#000", fontFamily: "Title" }}
                    key={page}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography
                      sx={{ color: "#fff", fontFamily: "Title" }}
                      textAlign="center"
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Avatar
            alt="ECEA"
            src={Logo}
            sx={{ display: { xs: "block", md: "none" }, marginRight: "10px" }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,

              fontFamily: "Title",
              fontWeight: 700,
              fontSize: "30px",
              letterSpacing: ".1rem",
              color: "#22d3ee",
              textDecoration: "none",
            }}
          >
            ECEA
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", marginLeft: "40px" },
            }}
          >
            {pages.map((page) => (
              <Link to={links[page]}>
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: "#fff",
                    display: "block",
                    fontWeight: "900",
                    marginRight: "50px",
                    fontSize: "15px",
                    textAlign: "center",
                    fontFamily: "Title",
                    transition: "0.3s",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      bottom: 0,
                      width: "100%",
                      height: "3px",
                      backgroundColor: "#22d3ee",
                      transition: "transform 0.2s ease-in-out",
                      transform: "scaleX(0)",
                    },
                    "&:hover::after": {
                      transform: "scaleX(1)",
                    },
                  }}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavBar;